/*
  STYLES FOR YVON TAILLANDIER'S WEBSITE
  Latest update : November 2018
  Author : Claire Chabas
*/
/* --------------------------------------
                ANIMATIONS
  -------------------------------------- */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes moveup {
  from {
    margin-top: 100px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes moveup {
  from {
    margin-top: 100px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
/* --------------------------------------
                GENERAL
  -------------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  font-family: 'Lato', Verdana, Arial, sans-serif;
  font-size: 20px;
  line-height: 1.5em;
  color: #555;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Vibur', cursive;
  line-height: 1.1em;
}
h1 {
  font-size: 2.8em;
  line-height: 1em;
  margin-bottom: 1em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.7em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1em;
}
h6 {
  font-size: 0.75em;
}
p {
  margin-bottom: 1.5em;
}
ul {
  list-style-type: none;
}
.italic {
  font-style: italic;
}
strong {
  font-weight: bold;
}
.align-right {
  text-align: right;
}
a {
  text-decoration: none;
}
a.link {
  color: rgba(97, 164, 86, 1);
  text-decoration: none;
  border-bottom: 1px solid rgba(97, 164, 86, 1);
}
a.link:hover {
  border-bottom: 0;
}
/* TRIX TEXT EDITOR */
.trix-text-editor strong {
  font-weight: bold;
}
.trix-text-editor em {
  font-style: italic;
}
.trix-text-editor a {
  color: rgba(97, 164, 86, 1);
  border-bottom: 1px solid rgba(97, 164, 86, 1);
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  background-size: 100% 200%;
  background-image: linear-gradient(
    to top,
    transparent 0%,
    transparent 50%,
    rgba(97, 164, 86, 1) 50%,
    rgba(97, 164, 86, 1) 100%
  );
  background-position: 0 100%;
  will-change: background-position;
}
.trix-text-editor a:hover {
  animation: backgroundAnimateIn 0.2s;
  animation-fill-mode: forwards;
}
.trix-text-editor h1 {
  font-size: 2em;
  margin-bottom: 0.3em;
}
.trix-text-editor ul {
  list-style: disc inside;
  text-indent: 2em;
}
blockquote {
  display: flex;
  justify-content: center;
  width: 60%;
  background: url('./img/quote-left-solid-v.svg') top left no-repeat;
  color: rgba(79, 57, 123, 0.7);
  border-right: 2px solid rgba(79, 57, 123, 0.7);
  background-size: 30px 30px;
  padding: 0.5em 1em 0.5em 2em;
  margin: 1em auto;
  font-size: 1.5em;
  font-family: 'Vibur', cursive;
}
@media only screen and (max-width: 1000px) {
  blockquote {
    width: 80%;
  }
}
@media only screen and (max-width: 650px) {
  blockquote {
    width: 100%;
  }
}
.trix-text-editor .trix-button--icon-strike,
.trix-text-editor .trix-button--icon-code,
.trix-text-editor .trix-button--icon-number-list,
.trix-text-editor .trix-button--icon-decrease-nesting-level,
.trix-text-editor .trix-button--icon-increase-nesting-level {
  display: none;
}
.back-to-top {
  position: fixed;
  bottom: 0.5em;
  right: 0.5em;
  font-size: 2em;
  color: rgba(79, 57, 123, 0.5);
}
.back-to-top:hover {
  color: rgba(79, 57, 123, 0.8);
  cursor: pointer;
}
/* --------------------------------------
                HEADER
  -------------------------------------- */
header.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5em 3em;
}
header.main-header h1 {
  margin-bottom: 0;
}
header .logo {
  width: 100%;
  min-width: 80%;
}
nav.menu {
  display: flex;
  margin-left: 3em;
}
nav.menu li {
  list-style-type: none;
  font-family: 'Vibur', cursive;
  font-size: 1.05em;
  line-height: 1.1em;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
  margin: 0 0.2em;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav.menu a {
  color: #000;
  background: linear-gradient(to bottom, transparent 62%, #fff87e 0) center
    center/0% 75% no-repeat; /* VERT : rgba(97,164,86,0.5) / VIOLET : rgba(79,57,123,0.5) */
  padding: 0 6px 2px 6px;
  cursor: pointer;
  letter-spacing: 0.08rem;
  transition: background-size 0.4s ease;
}
nav.menu a:hover {
  background-size: 100% 100%;
}
nav.menu a:active,
nav.menu a.isActive {
  background-size: 80% 100%;
}
.menu-s,
input[type='checkbox'] {
  display: none;
}
@media only screen and (max-width: 1100px) {
  header .logo {
    width: 90%;
  }
  nav.menu li {
    font-size: 1em;
  }
}
@media only screen and (max-width: 992px) {
  header .logo {
    width: 80%;
  }
  nav.menu li {
    font-size: 0.9em;
  }
}
/* ---------------- HAMBURGER MENU ---------------- */
@media only screen and (max-width: 768px) {
  label .menu.hide {
    display: none;
  }
  label .menu {
    flex-direction: column;
    z-index: 2;
  }
  label .menu li:hover {
    background: none;
    padding: 0;
  }
  label .menu-s {
    display: block;
    position: absolute;
    right: 50px;
    top: 25px;
    z-index: 2;
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50% 50% 50% 50%;
    transition: 0.5s ease-in-out;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7),
      0 0 0 0 rgba(255, 255, 255, 0.7);
    cursor: pointer;
  }
  label .hamburger {
    position: absolute;
    top: 30px;
    left: 18px;
    width: 25px;
    height: 2px;
    background: #000;
    display: block;
    -webkit-transform-origin: center;
    transform-origin: center;
    transition: 0.5s ease-in-out;
  }
  label .hamburger:after,
  label .hamburger:before {
    transition: 0.5s ease-in-out;
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: #000;
  }
  label .hamburger:before {
    top: -10px;
  }
  label .hamburger:after {
    bottom: -10px;
  }
  label input:checked + .menu-s {
    box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.7),
      0 0 0 1000px rgba(255, 255, 255, 0.7);
    border-radius: 0;
  }
  label input:checked + .menu-s .hamburger {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  label input:checked + .menu-s .hamburger:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    bottom: 0;
  }
  label input:checked + .menu-s .hamburger:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 0;
  }
  label input:checked + .menu-s + .menu {
    opacity: 1;
  }
  label .menu {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.25s 0s ease-in-out;
  }
  label a {
    margin-bottom: 1em;
    display: block;
    color: #000;
  }
}
@media only screen and (max-width: 650px) {
  header.main-header {
    margin: 1.5em 2em;
  }
  label .hamburger {
    left: 20px;
  }
}
@media only screen and (max-width: 430px) {
  label .hamburger {
    left: 18px;
  }
  label .menu-s {
    right: 40px;
  }
}
/* --------------------------------------
                  HOME
  -------------------------------------- */
.home {
  background: url('./img/portrait-yvont.jpg') center center no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 2em;
}
.home-content {
  opacity: 0;
  text-align: center;
  position: relative;
  top: 8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-animation: 0.5s fadein 1s forwards, 0.5s moveup 1s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: 0.5s fadein 1s forwards, 0.5s moveup 1s forwards; /* Firefox < 16 */
  -ms-animation: 0.5s fadein 1s forwards, 0.5s moveup 1s forwards; /* Internet Explorer */
  -o-animation: 0.5s fadein 1s forwards, 0.5s moveup 1s forwards; /* Opera < 12.1 */
  animation: 0.5s fadein 1s forwards, 0.5s moveup 1s forwards;
}
.home-quote {
  font-family: 'Vibur', cursive;
  color: #fff;
  opacity: 0.8;
  font-size: 2.5em;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  line-height: 1.2em;
}
.home-discover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 3em;
}
.home-discover .home-box {
  min-width: 7em;
  max-width: 12em;
  height: 7em;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5em;
  color: #000;
  text-decoration: none;
}
.home-discover .home-box:not(:first-child) {
  flex: 2;
  align-items: flex-end;
  background: url('./img/taillandierland/yt-lex-0.png') top center no-repeat;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.home-discover .home-box:not(:first-child):hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.home-discover .home-box:not(:first-child) span {
  background-color: rgba(255, 255, 255, 0.75);
  text-shadow: 1px 1px 10px #fff;
  width: 100%;
  height: 40%;
  padding: 2em 1em;
  line-height: 1.1em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: height 0.3s ease;
}
.home-discover .home-box:not(:first-child):hover span {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
}
.home-discover .home-box:first-child {
  color: #fff;
  text-align: left;
  height: 12em;
  width: auto;
  flex: 1;
}
.home-discover .home-box:not(:last-child) {
  margin-right: 1.5em;
}
.home-discover .home-box:nth-child(3) {
  background: url('./img/2000-Peinture-81x100.jpg') top center no-repeat;
  background-size: cover;
}
.home-discover .home-box:nth-child(4) {
  background: url('./img/2000-Peinture-100x81.jpg') top center no-repeat;
  background-size: cover;
}
.home-discover .home-box:nth-child(5) {
  background: url('./img/2001-Papier-50x64.jpg') top center no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 1400px) {
  .home-discover {
    top: 2em;
  }
}
@media only screen and (max-width: 1024px) and (max-height: 1366px) {
  .home-quote {
    font-size: 3em;
  }
  .home-discover {
    top: 13em;
  }
}
@media only screen and (max-width: 1024px) and (max-height: 620px) {
  .home-quote {
    font-size: 2.2em;
  }
  .home-discover {
    top: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .home-discover .home-box:not(:first-child) span {
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 970px) {
  .home-discover .home-box {
    height: 8em;
  }
  .home-discover .home-box:not(:first-child) span {
    height: 50%;
  }
}
@media only screen and (max-width: 850px) {
  .home-discover .home-box:first-child {
    position: absolute;
    display: block;
    max-width: none;
    width: 80vw;
    height: 5vh;
    text-align: center;
    top: -3em;
    font-weight: bold;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.4);
  }
  .home-discover {
    top: 1em;
  }
}
.mobile-discover {
  display: none;
}
@media only screen and (max-width: 768px) and (max-height: 1024px) {
  .home-quote {
    font-size: 3em;
  }
  .home-discover {
    top: 6em;
  }
}
@media only screen and (max-width: 768px) and (max-height: 620px) {
  .home-quote {
    font-size: 2.2em;
  }
  .home-discover {
    top: 3em;
  }
  .home-content {
    top: 5em;
  }
}
@media only screen and (max-width: 750px) {
  .home-discover {
    display: none;
  }
  .home-content {
    top: 6em;
  }
  .mobile-discover {
    display: block;
    position: relative;
    top: 2em;
    font-size: 1.2em;
    color: #fff;
    background: rgba(79, 57, 123, 0.8);
    border-radius: 3px;
    padding: 1em;
  }
  .mobile-discover:hover,
  .mobile-discover:focus,
  .mobile-discover:active {
    background: rgba(79, 57, 123, 1);
    cursor: pointer;
  }
  .mobile-discover .mobile-discover-nav {
    visibility: hidden;
    width: 50vw;
    background-color: #555;
    text-align: center;
    border-radius: 6px;
    padding: 1em 1em 0 1em;
    position: absolute;
    z-index: 3;
    bottom: 125%;
    left: 50%;
    margin-left: -25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .mobile-discover .mobile-discover-nav::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .mobile-discover .mobile-discover-nav a {
    color: #fff;
    margin-bottom: 1em;
  }
  .mobile-discover .mobile-discover-nav a:hover {
    color: rgba(255, 255, 255, 0.5);
  }
  .mobile-discover .show {
    visibility: visible;
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
  }
}
@media only screen and (max-width: 570px) {
  .mobile-discover {
    top: 2em;
    font-size: 1em;
  }
}
@media only screen and (max-width: 560px) {
  .mobile-discover {
    top: 0;
  }
}
@media only screen and (max-width: 420px) and (max-height: 830px) {
  .home-quote {
    font-size: 2.5em;
  }
  .mobile-discover {
    top: 1em;
  }
}
@media only screen and (max-width: 420px) and (max-height: 750px) {
  .home-quote {
    font-size: 2.2em;
  }
  .mobile-discover {
    top: 3em;
  }
}
@media only screen and (max-width: 380px) and (max-height: 680px) {
  .home-content {
    top: 5em;
  }
  .home-quote {
    font-size: 2.1em;
  }
  .mobile-discover {
    top: 0.5em;
  }
}
@media only screen and (max-width: 360px) and (max-height: 640px) {
  .home-quote {
    font-size: 2em;
  }
  .mobile-discover {
    font-size: 0.9em;
    top: 1em;
  }
}
@media only screen and (max-width: 320px) {
  .home-content {
    top: 5em;
  }
  .home-quote {
    font-size: 1.8em;
  }
  .mobile-discover {
    top: -1em;
    font-size: 0.8em;
  }
}
/* --------------------------------------
                FOOTER
  -------------------------------------- */
.main-footer {
  background: rgba(79, 57, 123, 0.8);
  color: #fff;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  justify-content: center;
}
.main-footer .footer {
  width: 90vw;
  padding-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.foot-bloc {
  min-width: 22%;
}
.foot-bloc h4 {
  margin-bottom: 0.5em;
}
.foot-bloc a,
.foot-copy-mob,
.foot-copy-mob a,
.foot-copy,
.encemoment div {
  color: #fff;
  font-size: 0.8em;
  line-height: 1.5em;
}
.foot-copy-mob a,
.foot-copy a {
  font-size: 1em;
}
.foot-bloc a {
  line-height: 2em;
}
.foot-bloc a:hover,
.foot-copy-mob a:hover {
  text-decoration: underline;
}
.foot-bloc:not(:first-child) {
  flex-shrink: 1;
}
.foot-bloc:nth-child(2) {
  padding-left: 2%;
}
.foot-bloc:first-child {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.foot-bloc:first-child div {
  margin-bottom: 1em;
}
.foot-bloc:first-child div i {
  font-size: 3em;
  opacity: 0.6;
  text-shadow: 0 1px 1px #423166;
}
.foot-bloc:first-child div:nth-child(2) {
  font-family: 'Vibur', cursive;
  font-size: 2em;
  line-height: 1.2em;
  opacity: 0.6;
  text-shadow: 0 1px 1px #423166;
}
.foot-bloc:last-child {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  text-align: center;
}
.foot-bloc:last-child .foot-img {
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.foot-bloc:last-child .foot-img img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.foot-copy-mob {
  display: none;
}
@media only screen and (max-width: 1000px) {
  .foot-bloc:not(:first-child) {
    padding-left: 2%;
  }
}
@media only screen and (max-width: 700px) {
  .main-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }
  .foot-bloc:first-child,
  .foot-bloc:last-child {
    display: none;
  }
  .foot-bloc {
    font-size: 0.9em;
    width: 100%;
    margin-bottom: 2em;
    text-align: center;
  }
  .foot-copy-mob {
    display: block;
    align-self: stretch;
    background-color: #423166;
    padding: 1em 0;
    text-align: center;
    font-size: 0.6em;
  }
}
/* --------------------------------------
            PAGES WRAPPERS
  -------------------------------------- */
.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container {
  display: flex;
  justify-content: center;
}
.bio,
.contact,
.list-page,
.item-page,
.taillandier-land,
.figuration,
.rencontres,
.critique-art,
.le-peintre,
.mentions {
  width: 85vw;
}
.bio,
.contact,
.list-page,
.item-page,
.oeuvres,
.taillandier-land,
.figuration,
.rencontres,
.critique-art,
.le-peintre,
.mentions {
  height: 100%;
  padding-top: 1em;
}
.bio,
.contact,
.list-page,
.item-page,
.oeuvres,
.taillandier-land,
.figuration,
.rencontres,
.critique-art,
.le-peintre,
.mentions {
  padding-bottom: 3em;
}
.bio-text,
.home-discover .home-box,
.contact p,
.contact input,
.contact textarea,
.list-page,
.item-page,
.item-page-date,
.item-page-link a,
.oeuvres-page,
.oeuvres-themes li a,
.taillandier-land p,
.figuration p,
.rencontres p,
.critique-art p,
.le-peintre p,
.mentions p {
  font-size: 0.9em;
}
/* --------------------------------------
            PAGE : NOT FOUND
  -------------------------------------- */
.not-found {
  text-align: center;
  margin: 3em 0;
}
/* --------------------------------------
            PAGE : BIOGRAPHY
  -------------------------------------- */
.bio-para {
  margin-bottom: 2em;
}
.bio-date {
  color: rgba(97, 164, 86, 1);
}
.bio-articles-list {
  font-size: 0.8em;
  font-style: italic;
  display: block;
  padding-left: 2em;
}
.bio-img {
  float: right;
  width: 25%;
  text-align: right;
  font-size: 0.8em;
  line-height: 1.2em;
  background-color: #eee;
  margin: 0 2em;
}
.bio-img img {
  width: 100%;
}
.bio-img p {
  padding: 0.5em 1em;
}
.bio-img.left {
  float: left;
  margin-left: 0;
  margin-right: 3em;
}
.bio-img.fullwidth {
  float: none;
  width: 55%;
  text-align: center;
  margin: 2em auto;
}
.bio-img.fullwidth p {
  text-align: left;
  padding-bottom: 2em;
}
.bio-img-big {
  width: 40%;
}
.bio-img-vbig {
  width: 50%;
}
.bio-imgs-line {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-bottom: 1em;
}
.bio-imgs-line div {
  flex-grow: 1;
  margin: 1em;
}
.para-block {
  display: inline-block;
}
.bio-img.pic-1967 {
  background-color: #fff;
}
.bio-img.pic-1967 p {
  position: relative;
  bottom: 8em;
  right: 2em;
  background-color: #eee;
  width: 60%;
  padding: 2em 1em 3em 2em;
}
.ceresfranco {
  margin-top: -1em;
  padding-bottom: 1em;
}
.ceresfranco p {
  line-height: 1.6em;
  padding: 1em 2em 0 2em;
}
.bio-separator {
  display: inline-block;
}
@media only screen and (max-width: 1024px) {
  .bio-img {
    width: 40%;
    margin-bottom: 1em;
  }
  .ceresfranco {
    float: none;
    width: 100%;
    margin: 0 0 2em 0;
  }
}
@media only screen and (max-width: 700px) {
  .bio-date {
    font-size: 1.2em;
  }
  .bio-text,
  .bio-img p {
    font-size: 0.8em;
  }
  .bio-img {
    float: none;
    width: 100%;
    margin: 0 0 2em 0;
  }
  .bio-img-big,
  .bio-img-vbig {
    width: 100%;
    height: auto;
  }
  .bio-imgs-line {
    flex-direction: column;
  }
}
/* --------------------------------------
            PAGE : CONTACT
-------------------------------------- */
.contact p {
  margin-bottom: 3em;
}
.contact form div {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
.contact form div:nth-child(3) {
  align-items: flex-start;
}
.contact label {
  font-family: 'Vibur', cursive;
  font-size: 1.5em;
  width: 25vw;
  margin-right: 1em;
}
.contact input,
.contact textarea {
  width: 100%;
  outline: none;
  padding: 1em;
  color: #555;
  border-radius: 6px;
  border: 1px solid #555;
}
.contact input:focus,
.contact textarea:focus {
  border: 1px solid rgba(79, 57, 123, 0.5);
  box-shadow: 0 1px 3px rgba(79, 57, 123, 0.5);
  -webkit-box-shadow: 0 1px 3px rgba(79, 57, 123, 0.5);
}
.contact input {
  height: 50px;
}
.contact textarea {
  min-height: 200px;
  resize: none;
}
.contact input::-webkit-input-placeholder,
.contact input:-moz-placeholder,
.contact textarea::-webkit-input-placeholder,
.contact textarea:-moz-placeholder {
  color: #bbb;
}
.contact button {
  font-size: 1em;
  color: #fff;
  background: rgba(79, 57, 123, 0.7);
  border-radius: 6px;
  padding: 1em 3em;
  width: 100%;
}
.contact button:hover,
.contact button:focus,
.contact button:active {
  background: rgba(79, 57, 123, 1);
  cursor: pointer;
}
.contact button i {
  margin-left: 0.5em;
}
.contact .contact-img {
  display: flex;
  justify-content: center;
  margin-top: 3em;
}
.contact-success {
  /* display: none; */
  margin-bottom: 100px;
  color: rgba(87, 183, 63, 1);
  background-color: rgba(87, 183, 63, 0.1);
  font-weight: bold;
  text-align: center;
  border: 1px solid rgba(87, 183, 63, 1);
  border-radius: 6px;
  padding: 1em 0;
}
.contact-error {
  /* display: none; */
  margin-bottom: 100px;
  color: rgba(219, 37, 58, 1);
  background-color: rgba(219, 37, 58, 0.1);
  font-weight: bold;
  text-align: center;
  border: 1px solid rgba(219, 37, 58, 1);
  border-radius: 6px;
  padding: 1em 0;
}
/* .contact p.show {
  display: block;
} */
@media only screen and (max-width: 812px) {
  .contact {
    font-size: 0.9em;
  }
  .contact form div {
    flex-direction: column;
    align-items: flex-start;
  }
  .contact label {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.3em;
  }
}
@media only screen and (max-width: 550px) {
  .contact .contact-img {
    margin-top: 2em;
  }
  .contact img {
    width: 80%;
  }
}
/* --------------------------------------
        PAGE : ACTUS/EXPOS LIST
-------------------------------------- */
.item-link-mob {
  display: none;
}
.list-page {
  font-size: 0.8em;
}
.list-page h1 {
  margin-bottom: 2.5em;
}
.list-page h3 {
  font-size: 1.7em;
  width: 100%;
  padding-bottom: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-page1 > .list-item:first-child {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  border: 0;
}
.list-page1 > .list-item:first-child .item-img {
  width: 50%;
  height: 38vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
}
.list-page1 > .list-item:first-child .item-img img {
  width: 100%;
  max-height: auto;
}
.list-page1 > .list-item:first-child .item-content {
  width: 65%;
  z-index: 1;
  position: absolute;
  left: 30%;
  height: 30%;
  background: #fff;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 2px solid rgba(79, 57, 123, 0.7);
}
.list-page1 > .list-item:first-child .item-date {
  margin-bottom: 0 !important;
}
.latest-tag {
  display: none;
}
.list-page1 > .list-item:first-child .latest-tag {
  display: inline;
}
.list-page1 > .list-item:first-child .latest-tag span {
  background-color: rgba(79, 57, 123, 0.7);
  color: #fff;
  padding: 0.2em;
}
.list {
  margin-bottom: 4em;
  margin-top: -1em;
}
.list .avion {
  margin-bottom: 0 !important;
  margin-top: 4em !important;
}
.list-item {
  display: flex;
  height: 25vh;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 1.5em;
  margin-top: 1.5em;
}
.list-item:last-child {
  border-bottom: 0;
}
.list-item .item-img {
  width: 30%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.list-item .item-img img {
  width: 100%;
  height: auto;
}
.list-item .item-content {
  width: 100%;
  height: 100%;
  padding: 1em 1em 1em 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.item-date {
  font-size: 0.8em;
}
.item-text-link {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.item-text {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-link {
  display: block;
  align-self: flex-end;
  width: 12em;
}
.item-link a {
  color: rgba(79, 57, 123, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
  border: 1px solid #fff;
}
.item-link:hover a {
  border: 1px solid rgba(79, 57, 123, 0.7);
  border-radius: 6px;
  transition: border 0.4s ease;
  text-decoration: none;
}
.item-link img {
  width: 15%;
  margin-right: 1em;
}
/* START OF MOBILE STYLES */
@media only screen and (max-width: 992px) {
  .list-page,
  .list-page1 {
    font-size: 0.8em;
  }
  .list-page h1 {
    margin-bottom: 2em;
  }
  .list .avion img {
    width: 20% !important;
  }
  .list-page1 > .list-item:first-child {
    display: flex;
    justify-content: space-between;
  }
  .list-page1 > .list-item:first-child .item-img {
    height: 35vh;
  }
  .list-page1 > .list-item:first-child {
    margin-bottom: 5em;
  }
  .list-page1 > .list-item:first-child .item-content {
    height: 30% !important;
    width: 50%;
    border-right: 0;
  }
  .list-page1 > .list-item:first-child .item-link-mob {
    display: block;
    z-index: 1;
  }
  .list-item {
    height: 20vh !important;
  }
  .list-item .item-content {
    justify-content: space-between;
    padding: 0.5em 1em 0 2em;
  }
  .list-item:not(:first-child) .item-img img {
    height: 100% !important;
    width: auto !important;
  }
  .item-date {
    font-size: 0.8em;
    margin: 0;
  }
  .item-text-link {
    display: none !important;
  }
  .item-link-mob {
    display: block;
  }
  .item-link-mob a {
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid rgba(79, 57, 123, 0.7);
    border-radius: 6px;
    padding: 1em;
    font-size: 1.3em;
    color: rgba(79, 57, 123, 0.7);
  }
  .item-link-mob a:hover,
  .item-link-mob a:focus,
  .item-link-mob a:active {
    background: rgba(79, 57, 123, 0.7);
    color: #fff;
    border: 1px solid #fff;
  }
}
/* END OF MOBILE STYLES */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.pagination span {
  color: rgba(79, 57, 123, 0.7);
  border: 1px solid rgba(79, 57, 123, 0.7);
  border-radius: 6px;
  margin: 1em;
  padding: 1em 1.5em;
  transition: all 0.3s ease;
}
.pagination span:hover {
  background: rgba(79, 57, 123, 0.7);
  color: #fff;
  cursor: pointer;
}
/* --------------------------------------
        PAGE : UNE ACTU/EXPO
-------------------------------------- */
.item-page h1 {
  margin-bottom: 0.3em;
}
.item-page-subtitle {
  font-size: 1em;
  font-weight: bold;
}
.item-page-main-img {
  width: 20vw;
}
.item-page-main-img img {
  width: 100%;
  height: auto;
  float: left;
  margin: 0 2em 2em 0;
}
.item-page-images,
.oeuvres-list {
  clear: left;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.column {
  flex: 25%;
  max-width: 25%;
  padding-right: 1em;
}
.oeuvres-list .column:last-child {
  padding-right: 0;
}
.column img {
  width: 100%;
  vertical-align: middle;
  margin-bottom: 1em;
}
/* Making a 3 columns layout on screens below 1200px */
@media only screen and (max-width: 1200px) {
  .column {
    flex: 33%;
    max-width: 33%;
  }
}
/* Making a 2 columns layout on screens below 1024px */
@media only screen and (max-width: 1024px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}
/* Making a 1 column layout on screens below 640px */
@media only screen and (max-width: 640px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
  .oeuvres-list .column {
    padding-right: 0;
  }
}
.item-page-video {
  width: 100%;
  display: flex;
  justify-content: center;
}
.item-page .avion,
.list .avion {
  clear: left;
  text-align: center;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
}
.item-page .avion img,
.list .avion img {
  width: 10%;
}
.item-page .avion.hide {
  display: none;
}
.item-page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  transition: all 0.3s ease;
}
.item-page-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 10vh;
  border: 1px solid rgba(79, 57, 123, 0.7);
  border-radius: 6px;
  padding: 1.5em 0;
  outline: none;
  color: rgba(79, 57, 123, 0.7);
}
.item-page-link img {
  width: 12%;
  margin-right: 1em;
}
.item-page-link .white-guy {
  display: none;
  transition: all 0.3s ease;
}
.item-page-link:hover a {
  color: #fff;
  background: rgba(79, 57, 123, 0.7);
  border: none;
}
.item-page-link:hover .purple-guy {
  display: none;
}
.item-page-link:hover .white-guy {
  display: block;
}
/* EXPO PAGE : PRACTICAL INFOS */
.item-page .expo-infos {
  width: 100%;
  background-color: rgba(79, 57, 123, 0.15);
  border-radius: 6px;
  display: flex;
  margin: 2em 0;
  padding: 1em;
  font-size: 0.8em;
}
.item-page .expo-infos div {
  flex-grow: 1;
  min-width: 15%;
  margin: 0 1em;
  border-right: 1px solid rgba(79, 57, 123, 0.1);
}
.item-page .expo-infos div:first-child {
  min-width: 20%;
}
.item-page .expo-infos div:nth-child(2) {
  min-width: 30%;
}
.item-page .expo-infos div:last-child {
  min-width: 10%;
  border-right: 0;
}
.item-page .expo-infos div:last-child p {
  text-indent: 2em;
}
.item-page .expo-infos p {
  margin-bottom: 0;
}
.item-page .expo-infos i {
  margin-right: 1em;
}
.item-page .expo-infos a {
  color: #555;
  text-decoration: underline;
}
.item-page .expo-infos a:hover {
  text-decoration: none;
}
/* --------------------------------------
      IMAGE POPUP EFFECT ONCLICK
-------------------------------------- */
img:not(.logo):hover {
  cursor: pointer;
  opacity: 0.7;
}
.popup {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.popup-content {
  animation-name: zoom;
  animation-duration: 0.6s;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup-content img {
  width: auto;
  height: 100%;
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 2em;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 812px) {
  .popup-content img {
    width: 100%;
    height: auto;
  }
}
.disabled-from-clicks {
  pointer-events: none;
}
/* --------------------------------------
            PAGE : OEUVRES
-------------------------------------- */
.oeuvres {
  width: 90vw;
}
.oeuvres h1 {
  margin-bottom: 0.8em;
}
.oeuvres-page {
  display: flex;
}
.oeuvres-themes {
  width: 18vw;
  margin-right: 3em;
}
.oeuvres-list {
  width: 100%;
}
.oeuvres-themes h2 {
  padding-bottom: 0.5em;
}
.oeuvres-themes li {
  border-bottom: 1px solid #ddd;
  transition: all 0.2s ease-in-out;
}
.oeuvres-themes li:hover,
.oeuvres-themes li.active-theme {
  padding-left: 1em;
  -webkit-box-shadow: inset 0px 0px 0px 2px rgba(97, 164, 86, 0.8);
  -moz-box-shadow: inset 0px 0px 0px 2px rgba(97, 164, 86, 0.8);
  box-shadow: inset 0px 0px 0px 2px rgba(97, 164, 86, 0.8);
}
.oeuvres-themes li:last-child {
  border-bottom: none;
}
.oeuvres-themes li:last-child:hover,
.oeuvres-themes li:last-child.active-theme {
  -webkit-box-shadow: inset 0px 0px 0px 2px rgba(97, 164, 86, 0.8);
  -moz-box-shadow: inset 0px 0px 0px 2px rgba(97, 164, 86, 0.8);
  box-shadow: inset 0px 0px 0px 2px rgba(97, 164, 86, 0.8);
}
.oeuvres-themes li a {
  color: #555;
  display: block;
  padding: 0.7em;
  padding-left: 0;
}
.oeuvres-themes li:hover a,
.oeuvres-themes li.active-theme a {
  color: rgba(97, 164, 86, 0.8);
}
.oeuvre-item {
  margin-bottom: 1em;
}
.oeuvre-item img {
  margin-bottom: 0;
}
.oeuvre-infos {
  background: #f2f2f2;
  padding: 0.5em;
}
.oeuvre-infos p {
  margin-bottom: 0.5em;
  font-size: 0.8em;
  line-height: 1.2em;
}
.oeuvre-infos span {
  display: inline-block;
  font-size: 0.8em;
  border: 1px solid rgba(79, 57, 123, 0.8);
  color: rgba(79, 57, 123, 0.8);
  padding: 0.2em;
  line-height: 1em;
  margin-right: 0.3em;
}
.oeuvre-poeme div {
  text-align: center;
}
@media only screen and (max-width: 812px) {
  .oeuvres {
    width: 80vw;
    padding-top: 0;
    margin-top: -1em;
  }
  .oeuvres h1 {
    font-size: 1.9em;
  }
  .oeuvres-page {
    flex-direction: column;
  }
  .oeuvres-themes {
    width: 80vw;
    margin-bottom: 1em;
  }
  .oeuvres-themes h2 {
    font-family: 'Lato', Verdana, Arial, sans-serif;
    font-size: 0.7em;
    font-weight: bold;
    background: #eee;
    padding: 1em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .oeuvres-themes ul.hide {
    display: none;
  }
  .oeuvres-themes li {
    padding-left: 1em;
    font-size: 0.7em;
  }
  .oeuvres-themes li:hover,
  .oeuvres-themes li.active-theme,
  .oeuvres-themes li:last-child:hover,
  .oeuvres-themes li:last-child.active-theme {
    box-shadow: none;
  }
  .oeuvres-themes li:active,
  .oeuvres-themes li.active-theme {
    background: rgba(97, 164, 86, 0.8);
  }
  .oeuvres-themes li:active a,
  .oeuvres-themes li.active-theme a {
    color: #fff;
  }
  .oeuvres-themes li.active-theme a {
    font-weight: bold;
  }
}
/* --------------------------------------
        PAGE : TAILLANDIER-LAND
-------------------------------------- */
.taillandier-land h2 {
  margin: 1em 0;
}
.taillandier-land h3 {
  margin-top: 2em;
}
.taillandier-land .img-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* text-align: center; */
  margin-bottom: 1em;
}
.taillandier-land .img-center img {
  width: 50%;
}
.taillandier-land .img-center.origines-sang img {
  width: 80%;
}
.taillandier-land .img-center p,
.taillandier-land .img-expo-2005 p {
  width: 80%;
  background: #eee;
  margin-top: 0;
  padding: 1em;
  font-size: 0.8em;
  text-align: left;
  line-height: 1.5em;
}
.taillandier-land .img-right img {
  width: 200%;
  margin: 1em 0 1em 2em;
}
.taillandier-land .float-left img,
.taillandier-land img.float-left {
  float: left;
  width: 30%;
  margin: 1em 2em 1em 0;
}
.taillandier-land .float-right {
  float: right;
  width: 30%;
  margin-left: 2em;
}
.taillandier-land .float-right img {
  width: 100%;
}
.taillandier-land .float-right.img151 {
  width: 20%;
}
.taillandier-land .img-expo-2005 {
  float: right;
  width: 20%;
  margin: 1em 0 1em 2em;
}
.taillandier-land .img-expo-2005 img,
.taillandier-land .voyageoeil img {
  width: 100%;
}
.taillandier-land .img-expo-2005 p {
  width: 100%;
  margin-top: -0.5em;
}
.taillandier-land .img-center.img159 img,
.taillandier-land .img-center .img76 {
  width: 30%;
}
.taillandier-land .fabuliste {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3em 0;
}
.taillandier-land .fabuliste div {
  border-left: 2px solid #eee;
  padding: 2em;
}
.taillandier-land .fabuliste h3 {
  margin: 0 0 1em 0;
}
.taillandier-land .fabuliste p {
  font-style: italic;
}
.taillandier-land .fabuliste img {
  width: 50%;
}
.taillandier-land .lexique {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2em 1em 2em;
}
.taillandier-land .main-img img {
  width: 100%;
  margin-bottom: 2em;
}
.taillandier-land .lexique-img {
  width: 45%;
}
.taillandier-land .lexique-text {
  width: 55%;
}
.taillandier-land .left .lexique-text {
  padding-left: 3em;
}
.taillandier-land .right .lexique-text {
  padding-right: 3em;
}
.taillandier-land .lexique-img img {
  width: 100%;
}
@media only screen and (max-width: 1000px) {
  .taillandier-land .float-left img,
  .taillandier-land img.float-left,
  .taillandier-land .float-right {
    width: 40%;
  }
  .taillandier-land .float-right.img151,
  .taillandier-land .img-expo-2005 {
    width: 30%;
  }
  .taillandier-land .lexique-img {
    width: 55%;
  }
}
@media only screen and (max-width: 650px) {
  .taillandier-land .float-left img,
  .taillandier-land img.float-left,
  .taillandier-land .float-right {
    width: 50%;
  }
  .taillandier-land .float-right.img151,
  .taillandier-land .img-expo-2005,
  .taillandier-land .img-center.img159 img,
  .taillandier-land .img-center .img76 {
    width: 40%;
  }
  .taillandier-land .lexique-img {
    width: 65%;
  }
  .taillandier-land .img-center.origines-sang img,
  .taillandier-land .img-center p {
    width: 100%;
  }
  .taillandier-land .fabuliste,
  .taillandier-land .lexique {
    flex-direction: column;
    margin: 0 0 1em 0;
  }
  .taillandier-land .fabuliste img {
    width: 80%;
  }
  .taillandier-land .lexique-img,
  .taillandier-land .lexique-text {
    width: 90%;
  }
  .taillandier-land .left .lexique-text {
    padding-left: 0;
  }
  .taillandier-land .right .lexique-text {
    padding-right: 0;
  }
  .taillandier-land .main-img img {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 500px) {
  .taillandier-land .float-left img,
  .taillandier-land img.float-left,
  .taillandier-land .float-right,
  .taillandier-land .img-expo-2005 {
    float: none;
    display: block;
    width: 100%;
    margin: 0 auto 1em auto;
  }
  .taillandier-land .float-right.img151,
  .taillandier-land .img-expo-2005,
  .taillandier-land .img-center.img159 img,
  .taillandier-land .img-center .img76 {
    width: 60%;
    margin-bottom: 1em;
  }
  .taillandier-land .float-left img,
  .taillandier-land img.float-left,
  .taillandier-land .float-right {
    width: 70%;
  }
  .taillandier-land .img-center img {
    width: 90%;
  }
}
/* --------------------------------------
    PAGE : LA FIGURATION LIBERATRICE
-------------------------------------- */
.figuration p {
  margin: 0.5em 0;
}
.figuration .img-center {
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}
.figuration .img-center img {
  width: 50%;
}
.figuration .img-center.img5 img {
  width: 80%;
}
.figuration .img-right img {
  float: right;
  width: 30%;
  margin: 1em 0 1em 1em;
}
.figuration .img-left img {
  float: left;
  width: 30%;
  margin: 1em 1em 1em 0;
}
.figuration .fig-narr {
  background: #eee;
  padding: 3em;
  margin-top: 3em;
  height: 30em;
}
.figuration .fig-narr h3 {
  margin-bottom: 1em;
}
.figuration .fig-narr p {
  min-width: 30%;
}
.figuration .fig-narr img {
  float: right;
  height: 100%;
  width: auto;
  margin-left: 3em;
  margin-bottom: 2em;
}
.figuration .fig-narr .fig-narr-img-mob {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .figuration .fig-narr {
    height: 39em;
  }
  .figuration .fig-narr img {
    height: 80%;
  }
}
@media only screen and (max-width: 1000px) {
  .figuration .fig-narr {
    height: 38em;
  }
  .figuration .fig-narr img {
    margin-left: 2em;
    margin-bottom: 1em;
    height: 70%;
  }
}
@media only screen and (max-width: 900px) {
  .figuration .fig-narr {
    height: 38em;
  }
  .figuration .fig-narr img {
    height: 60%;
  }
  .figuration .img-right img,
  .figuration .img-left img {
    width: 40%;
  }
}
@media only screen and (max-width: 800px) {
  .figuration .fig-narr {
    padding: 2em;
  }
}
@media only screen and (max-width: 700px) {
  .figuration .img-right img,
  .figuration .img-left img {
    width: 50%;
  }
  .figuration .img-center img {
    width: 60%;
  }
  .figuration .fig-narr {
    display: flex;
    flex-direction: column;
    height: 52em;
  }
  .figuration .fig-narr img.fig-narr-img {
    display: none;
  }
  .figuration .fig-narr img.fig-narr-img-mob {
    display: block;
    float: none;
    margin: 2em auto;
    height: 40%;
    width: auto;
  }
}
@media only screen and (max-width: 600px) {
  .figuration .fig-narr {
    height: 57em;
  }
  .figuration .fig-narr img.fig-narr-img-mob {
    height: 30%;
  }
}
@media only screen and (max-width: 500px) {
  .figuration .fig-narr img.fig-narr-img-mob {
    height: 25%;
  }
  .figuration .img-right,
  .figuration .img-left {
    width: 100%;
    text-align: center;
  }
  .figuration .img-right img,
  .figuration .img-left img {
    float: none;
    margin: 0 auto;
    width: 80%;
  }
  .figuration .img-center img {
    width: 80%;
  }
}
/* --------------------------------------
        PAGE : LES RENCONTRES
-------------------------------------- */
.rencontres h1,
.rencontres h3,
.rencontres h4 {
  margin-bottom: 0.5em;
}
.rencontre-pic img {
  float: left;
  width: 30%;
  margin-right: 2em;
  margin-bottom: 1em;
}
.rencontre-pic-mob {
  display: none;
}
@media only screen and (max-width: 1000px) {
  .rencontre-pic img {
    width: 40%;
  }
}
@media only screen and (max-width: 800px) {
  .rencontre-pic img {
    width: 50%;
  }
}
@media only screen and (max-width: 500px) {
  .rencontre-pic img {
    float: none;
    width: 100%;
  }
  .rencontre-pic {
    display: none;
  }
  .rencontre-pic-mob {
    display: block;
  }
}
.bonhomme {
  display: flex;
  justify-content: center;
  margin: 3em 0;
  width: 100%;
}
.bonhomme img {
  width: 7%;
  height: 100%;
}
@media only screen and (max-width: 1000px) {
  .bonhomme img {
    width: 10%;
  }
}
@media only screen and (max-width: 700px) {
  .bonhomme img {
    width: 15%;
  }
}
@media only screen and (max-width: 400px) {
  .bonhomme img {
    width: 20%;
  }
}
.pharaon {
  float: right;
  width: 40%;
  margin: 2em 0 2em 2em;
}
.to-do {
  display: none;
} /* Delete this class when the texts are written */
/* --------------------------------------
          PAGE : LE CRITIQUE
-------------------------------------- */
.critique-art h2 {
  margin: 1em 0;
}
.critique-art .text-img {
  display: flex;
  align-items: center;
}
.critique-art .text-img img {
  width: 30%;
  margin-left: 2em;
}
.critique-art .extrait {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 2em;
}
.critique-art .extrait-img {
  width: 30%;
}
.critique-art .extrait-text {
  width: 70%;
}
.critique-art .left .extrait-text {
  padding: 3em 0 3em 3em;
}
.critique-art .right .extrait-text {
  padding: 3em 3em 3em 0;
}
.critique-art .extrait-img img {
  width: 100%;
}
.critique-art .extrait-img.lanskoy img {
  width: 80%;
}

@media only screen and (max-width: 1300px) {
  .critique-art .extrait {
    margin: 0 2em;
  }
  .critique-art .extrait-img {
    width: 40%;
  }
}
@media only screen and (max-width: 1100px) {
  .critique-art .extrait-img {
    width: 50%;
  }
  .critique-art .left .extrait-text {
    padding: 2em 0 2em 2em;
  }
  .critique-art .right .extrait-text {
    padding: 2em 2em 2em 0;
  }
}
@media only screen and (max-width: 1000px) {
  .critique-art .text-img {
    flex-direction: column;
  }
  .critique-art .text-img img {
    margin-left: 0;
  }
  .critique-art .extrait {
    align-items: flex-start;
  }
  .critique-art .extrait-img {
    width: 30%;
    padding-top: 2em;
  }
  .critique-art .left .extrait-text {
    padding: 1em 0 1em 2em;
  }
  .critique-art .right .extrait-text {
    padding: 1em 2em 1em 0;
  }
}
@media only screen and (max-width: 900px) {
  .critique-art .extrait {
    margin: 2em 0 0 0;
  }
  .critique-art .extrait:nth-child(n + 1) {
    flex-direction: column-reverse;
    align-items: center;
  }
  .critique-art .extrait:nth-child(2n + 1) {
    flex-direction: column;
    align-items: center;
  }
  .critique-art .extrait-img {
    width: 40%;
    padding: 0;
  }
  .critique-art .extrait-text {
    width: 100%;
    margin: 0;
  }
  .critique-art .left .extrait-text {
    padding: 0;
  }
  .critique-art .right .extrait-text {
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .critique-art .extrait-img {
    width: 50%;
  }
}
@media only screen and (max-width: 500px) {
  .critique-art .text-img img {
    width: 40%;
  }
}
/* --------------------------------------
          PAGE : LE PEINTRE
-------------------------------------- */
.le-peintre h2 {
  margin: 1em 0 0.5em 0;
}
.le-peintre .atelier {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3em;
}
.le-peintre .atelier div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 40%;
}
.le-peintre .atelier iframe {
  width: 55%;
}
.le-peintre .img-center {
  width: 100%;
  text-align: center;
}
.le-peintre .img-center img {
  width: 30%;
}
.le-peintre .img-right {
  float: right;
  width: 30%;
  margin-left: 2em;
}
.le-peintre .img-left {
  float: left;
  width: 30%;
  margin-right: 2em;
}
.le-peintre .img-right img,
.le-peintre .img-left img {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .le-peintre .atelier {
    flex-direction: column;
  }
  .le-peintre .atelier div,
  .le-peintre .atelier iframe {
    width: 100%;
    max-width: 100%;
  }
  .le-peintre .img-right,
  .le-peintre .img-left {
    width: 40%;
  }
  .le-peintre .img-center img {
    width: 40%;
  }
}
@media only screen and (max-width: 800px) {
  .le-peintre .img-center img {
    width: 50%;
  }
  .le-peintre .img-right,
  .le-peintre .img-left {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .le-peintre .img-center img {
    width: 60%;
  }
  .le-peintre .img-right,
  .le-peintre .img-left {
    float: none;
    width: 100%;
    text-align: center;
    margin: 0 0 1em 0;
  }
  .le-peintre .img-right img,
  .le-peintre .img-left img {
    width: 70%;
    height: auto;
  }
}
/* --------------------------------------
        PAGE : MENTIONS LEGALES
-------------------------------------- */
.mentions h3 {
  margin-top: 1.5em;
}
/* --------------------------------------
        PAGE : EN CONSTRUCTION
-------------------------------------- */
.en-construction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em 0 3em 0;
}
.en-construction h2 {
  margin-bottom: 1em;
}
